<template>
<v-list>
  <h1 class="text-center">Клиенты</h1>
  <v-list-item
      v-for="client in CLIENTS"
      :key="client.id"
      @click="linkClient(client.id)"
  >
    <v-list-item-avatar>
      <v-icon>mdi-account</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ client.surname + ' ' + client.name + ' ' + client.second_name }}
      </v-list-item-title>

      <v-list-item-subtitle v-text="client.phone"></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon>
        <v-icon color="grey lighten-1">mdi-information</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
  </v-list>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  data() {
    return {
      clients: null,
    };
  },
  computed: {
    ...mapGetters([
        'CLIENTS',
    ])
  },
  methods: {
    linkClient(id) {
      this.$router.push(`clients/${id}`)
    },
    ...mapActions([
        'GET_CLIENTS_FROM_API',
    ])
  },
  mounted() {
    this.GET_CLIENTS_FROM_API()
    .then((response) => {
      if (response.data) {
        console.log('Data arrived!');
      }
    })
  },
}
</script>